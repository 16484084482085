import Profesiones from './../img/profesiones.jpg'



export default function SiderA() {
  return (
    <div className="me-12 d- mt-4 text-center"  >
      <div className='row'>
        <div className='col-12'><hr></hr></div>
        <h3>Estrategia digital & Copywriting</h3>
        <div className='col-12'><hr></hr></div>
      </div>

      <div className='row'>

         <div className="col-12 col-sm-6 boxcenter text-center">
          <p style={{ fontSize: '18px', textAlign: 'justify' }}>
            A quién de niña no le gustaba llevar su diario y empezar diciendo… Hola diario como estas y arriba la fecha… pues señoras y señores yo también fui una de ellas, recuerdo que mi hermano me mamaba gallo (termino en la costa para referirse que te tomaba del pelo o se burlaba de ti), diciéndome hola diario como estas y me ponía en burla delante de mis amiguitos de la infancia. 
<hr></hr>El caso es que desde muy pequeña, me ha gustado escribir, dejar mis impresiones en hojas blancas y vi una oportunidad de unir lo que me ha gustado con lo que me apasiona ahora y fue cuando decidí crear mi mundo pixar donde el copy y la creación de contenido liviano y real, se unieran de la mano y conectarán mi mundo mágico con lo que a mi alma le hace feliz y Voilá…
          </p>
        </div>

        <div className='col-12 col-sm-6'>
          <p >
            <img className='d-block w-100' src={Profesiones} alt='Mensajero' />
          </p>
        </div>

      </div>






    </div>

  )
}