
import { Link, NavLink } from 'react-router-dom';
import { Background } from 'react-parallax';

import Logo from './../img/logo2.png'

export default function Menu() {

  const claseActiva = "active";

  return (
    <div className=''>


      <div>
        <div className=' d-md-block d-none mt-2' style={{textAlign:'right'}}>

          <div>
            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-tiktok"></i>
            </a>
            
            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-youtube"></i>
            </a>
            
            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-twitter"></i>
            </a>


            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-spotify"></i>
            </a>


            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-instagram"></i>
            </a>
          </div>


        </div>


        <nav className="navbar navbar-expand-lg navbar-ligth  p-0 m-0 mt-2" style={{ backgroundColor: '#FFFFFF' }}>
          <div className="container-fluid">
            <a className="navbar-brand" href="#"><img src={Logo} alt="DBK Logo" height={72} /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className=" collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ms-auto ">

                <li className="nav-item">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/"   >Home</NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/nosotros"  >About me</NavLink>
                </li>

                <li className="nav-item">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/alturas"  >Copy and more</NavLink>
                </li>


                <li className="nav-item">
                  <NavLink className={(navData) => navData.isActive ? "nav-link mx-2 active" : "nav-link mx-2"} to="/contactenos"  >Talk 2me</NavLink>
                </li>

                <li className="nav-item flare" style={{display:'none'}}>
                  <a className="nav-link mx-3" href="http://www.consulta.ayeocupacionales.com.co/" target='blank' >
                    Consulta certificado<span className="ms-2">→</span></a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>



    </div>

  )

}