import React from 'react'
import banner from './../img/banners/banner1.png'

export const ImageBanner = () => {
    return (
        <div>
                <img style={{ width: '100%' }} src={banner}></img>
        </div>
    )
}
