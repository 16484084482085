export default function Aprobacion() {

    return (
        <div className="row">


<div className="col-6">
        <h4>Licencia ministerio</h4>
            <object data="http://www.consulta.cefecol.com/files/licencia.pdf#navpanes=0" type="application/pdf" width="100%" height="500px">
                <p>Alternative text - include a link <a href="http://www.consulta.cefecol.com/files/licencia.pdf">to the PDF!</a></p>
            </object>
        </div>

        
        <div className="col-6">
        <h4>Aprobación ministerio</h4>
        <object data="http://www.consulta.cefecol.com/files/aprobacion.pdf#navpanes=0" type="application/pdf" width="100%" height="500px">
                <p>Licencia <a href="http://www.consulta.cefecol.com/files/aprobacion.pdf">to the PDF!</a></p>
            </object>
        </div>


        </div>
    )

}