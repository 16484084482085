import Alturas from './../img/servicios/tsa.png'
import Analisis from './../img/servicios/analisis.png'
import Accidente from './../img/servicios/accidente.png'
import EspaciosConfinados from './../img/servicios/espacioconfinado.png'
import Formacion from './../img/servicios/formacion.png'
import Asesoria from './../img/servicios/asesoria.png'
import Sggst from './../img/servicios/sggst.png'
import copy1 from './../img/servicios/copy.jpg'
import copy2 from './../img/servicios/copy_2.jpg'
import copy3 from './../img/servicios/copy_3.jpg'


export default function Servicios() {
    return (
        <div className="row boxcenter text-center bg-white bodyImage">
                <figure className="snip0015 col-4">
                    <img src={copy1} alt="sample38" />
                    <figcaption>
                        <h2>Write <span>on</span></h2>
                        <p>Hagamoslo.</p>
                        <a href="#" />
                    </figcaption>
                </figure>
                <figure className="snip0015 col-4">
                    <img  src={copy2}  />
                    <figcaption>
                        <h2>How many <span>boards</span> </h2>
                        <p>Would the Mongols hoard, if the Mongol hordes got bored?.</p>
                        <a href="#" />
                    </figcaption>
                </figure>
                <figure className="snip0015 col-4">
                    <img  src={copy3}  />
                    <figcaption>
                        <h2>If your <span>knees</span></h2>
                        <p>By the end of the day, you ought to seriously re-examine your life.</p>
                        <a href="#" />
                    </figcaption>
                </figure>
        </div>

    )
}