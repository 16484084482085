

import Aprobacion from '../modules/Aprobacion';
import NuestrosServicios from '../modules/NuestrosServicios';
export default function Legal() {

    return (
        <div>
            <Aprobacion />
        </div>
    )

}