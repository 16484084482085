import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Menu from './components/modules/Menu';
import { BrowserRouter, Route,Routes } from 'react-router-dom';
import Home from './components/pages/Home';
import Nosotros from './components/pages/Nosotros';
import Contactenos from './components/pages/Contactenos';
import { useEffect } from 'react';
import Footer from './components/modules/Footer';
import Cursos from './components/pages/Cursos';
import Inscripcion from './components/pages/Inscripcion';
import Autorizacion from './components/pages/Autorizacion';
import TrabajoEnAlturas from './components/pages/TrabajoEnAlturas';
import Legal from './components/pages/Legal';

function App() {

  
  useEffect(()=>{
    
    document.title = "Adri Ardila M";
    //document.body.style.backgroundImage=Fondo;
    });


  return (
    <div className="container "  >
      
      
      <BrowserRouter>      
      <Menu></Menu>
      <Routes>
        <Route  path="/nosotros" element={<Nosotros/>} ></Route>
        <Route  path="/cursos" element={<Cursos/>} ></Route>
        <Route  path="/alturas" element={<TrabajoEnAlturas/>} ></Route>
        <Route  path="/contactenos" element={<Contactenos/>} ></Route>
        <Route  path="/legal" element={<Legal/>} ></Route>
        <Route  path="/autorizacion" element={<Autorizacion/>} ></Route>
        <Route  path="/inscripcion" element={<Inscripcion/>} ></Route>
        <Route  path="/" element={<Home/>}></Route>
        <Route  path="*" element={<Home/>}></Route>
      </Routes>      
      </BrowserRouter>      
      <Footer></Footer>
    </div>
  );
}

export default App;
