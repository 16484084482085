

export default function FormularioContacto() {
    return (
        <div className="row">
            
            <div className='col-12 col-sm-8 mt-2'>
                <img className="card-img-top" src={require('./../img/contacto.jpg')} alt="Card image cap" />
            </div>
            <div className='col-12 col-sm-4 p-4 '>
                <h6 className="text-uppercase fw-bold mb-4">Contáctenos</h6>
                <p><i className="fas fa-home me-3"></i>Cra 68C # 77 – 61. Bogotá, colombia</p>
                <p><i className="fas fa-envelope me-3"></i>servicios@ayeocupacionales.com</p>
                <p><i className="fas fa-phone me-3"></i>+57 3158002914</p>
            </div>
        </div>
    )
}

interface formularioContactoProps {
    celular: string;
    correo: string;
    direccion: string;
}