export default function Footer() {

  return (
    <div className=" mb-4">

      <footer className="text-center text-lg-start text-muted" style={{ backgroundColor: '#fff' }}>

        <section className="d-flex justify-content-center  p-4 border-bottom">


          <div className="me-5 d-none d-lg-block">
            <span>Siguenos en nuestras redes sociales:</span>
          </div>

          <div>
           

            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-tiktok"></i>
            </a>
            
            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-youtube"></i>
            </a>
            
            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-twitter"></i>
            </a>


            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-spotify"></i>
            </a>


            <a href="https://www.facebook.com/profile.php?id=100090285681210" target="blank" className="me-4 text-reset">
              <i className="fab fa-2x fa-instagram"></i>
            </a>
          </div>

        </section>

        <section className="">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">

              <div className="col-md-6 col-lg-6 col-xl-6 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <i className="fas fa-gem me-3"></i>Adri Ardila
                </h6>
                <p>
                Estratega digital, Marketera y Copywriter profesional,  apasionada por el contenido liviano y orgánico para una marca personal genuina y creativa.
                </p>
              </div>




              <div className="col-md-6 col-lg-6 col-xl-6 mx-auto mb-md-0 mb-4" style={{ textAlign: 'center' }} >

                <h6 className="text-uppercase fw-bold mb-4">Contactenos</h6>
                <p><i className="fas fa-home me-3"></i>Cra 68C # 77 – 61. Bogotá, colombia</p>
                <p><i className="fas fa-envelope me-3"></i>servicios@ayeocupacionales.com</p>
                <p><i className="fas fa-phone me-3"></i>+57 3158002914</p>
              </div>

            </div>

          </div>
        </section>



        <div className="text-center pt-4 pb-4 " style={{ backgroundColor: '#000', color: '#FFF' }}>
          <p>
            © 2022 Copyright: <a style={{ textDecoration: 'none' }} className="text-reset fw-bold" href="https://multinet.com.co/">  Multinet Soluciones Digitales</a>
          </p>
        </div>
      </footer>
    </div>


  )


}