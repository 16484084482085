

import NivelesTrabajoEnAlturas from '../modules/NivelesTrabajoEnAlturas';
export default function TrabajoEnAlturas() {

    return (
        <div>
            <NivelesTrabajoEnAlturas></NivelesTrabajoEnAlturas>
        </div>
    )

}