import Nosotros from './../img/nosotros.png'


export default function QuienesSomos() {

    return (

        <div>
            <div className="row mt-2">
                <div className="col-12 col-sm-8  text-center">

                    <p>
                        <strong style={{ fontSize: '30px' }}>About me</strong><br /><hr></hr>
                        Hi Soy Adri<br />
                        … y te cuento destellos de mi historia…<br />
                        Soy una chica sensible, apasionada, soñadora y humanamente imperfecta.<br />
                    </p>

                    <p>

                        Expreso mi esencia y mi ser como <b>estratega digital, copy y creadora de contenido</b> cool</p>
                   
                    <p>
                        En la constante búsqueda de mi autoconocimiento, me he podido encontrar, conocerme,
                        crecer y enfocar el escenario en que visualizo mis intereses personales y profesionales,
                        sin olvidar que camino hacía mi propósito de vida.
                    </p>
                    <p>
                    Cuando pienso en aquello que disfruto hacer <strong>(CRECER Y CREAR)</strong> con creatividad, se iluminan mis ojos y mi corazón estalla de dinamismo, dedicación, pasión y mucho amor.

                    </p>
                </div>

                <div className="col-12 col-sm-4">
                    <img src={Nosotros} width='100%' ></img>
                </div>

            </div>
        </div>
    )

}